.App {
  text-align: center;
}

:root {
  --bottom-navigation-height: 56px;
}

.app-header {
  font-family: Comic Sans, monospace;
  font-weight: bold;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(40px + 2vmin);
}

.background-image {
  height: calc(100% - var(--bottom-navigation-height));
  overflow: hidden;
  background-image: url("images/polish-flag-25-slow.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.justify-text {
  text-align: justify;
}

.scrollable {
  height: calc(80vh - var(--bottom-navigation-height));
  overflow: auto;
}

.stats-item {
  min-width: 275px;
}